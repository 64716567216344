.backBtn {
  border-radius: 50%;
  transition: 0.3s;
  position: absolute;
  left: -35px;
  color: #ff671f;
  cursor: pointer;
}

.backBtn:hover {
  background: rgb(238, 238, 238);
}