.cstmDrawer>div {
  height: 100vh;
  overflow-y: hidden;
}

.cstmDrawer {
  height: 100vh;
  overflow-y: hidden;

  .sidebarLogo {
    position: fixed;
    top: 22px;
    z-index: 9;
    left: 18px;
  }

  .logo {
    font-size: 1.2rem;
    font-weight: bold;
    color: #35035d;
  }

  .jYLogo {
    width: 50px;
    height: 50px;
  }

  .jYLogoHorizontal {
    width: 150px;
    position: absolute;
    top: -7px;
    left: -2px;
  }

  .jYLogoVertical {
    width: 56px;
    position: absolute;
    top: -15px;
    left: -15px;
  }


  .close-drawer-button {
    position: fixed !important;
    left: 190px;
    top: 18px;
    z-index: 9999;
  }
}



.website-layout-outer {
  padding: 0 !important;
  box-shadow: -7px 0px 10px #e8e8e8;
  // z-index: 9999;

  .cstmNavbar {
    // border: 1px solid red;
    display: flex;
    align-items: center;
    border-bottom: 0.5px solid rgb(231, 228, 228);
    position: sticky;
    top: 0;
    background-color: white;
    padding: 0px 20px;
    z-index: 1;
  }

  .website-layout {
    // width: 85%;
    height: 100vh;
    overflow: auto;
  }



  .cstm-logo {
    display: inline-block;
  }

  .burgerMenuBtn {
    margin: 0;
  }

  .logoHidden {
    display: none;
  }

  .logo {
    font-size: 1.2rem;
    font-weight: bold;
    color: #35035d;
  }

  .burgerMenuPlusHeading {
    // border: 1px solid red;
    // width: 230px;
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .burgerMenuPlusHeadingDisplayNone {
    display: none;
  }
}

@media (max-width:768px) {
  .cstmDrawer {
    position: absolute;


  }

  .website-layout-outer {
    margin-left: 52px;

    .cstmNavbar {
      padding: 5px;
    }
  }

  .cstmDrawer {

    .jYLogoVertical {
      width: 52px;
      position: absolute;
      top: -17px;
      left: -17px;
    }

    .jYLogoHorizontal {
      width: 140px;
      top: -13px;
      left: -10px;
    }
  }
}