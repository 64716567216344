.allcomponent {
   margin: 0px;
    width: 100vw;
    height: 100vh;
    font-size: 62.52%;
  
    .border {
        margin-right: inherit;
        display: flex;
        flex-direction: row;
        justify-content: center;
        .button {
            margin: 1rem;
        }

    }
   .back-button{
    color: rgb(232, 12, 232);
    background-color: rgb(7, 7, 7);
    margin: 1rem;
    border: 2px solid red;
   }
   
.formdata{   
        padding: 2rem;
}

.textbox{
    border:1px solid rgb(105, 105, 105);
}
.remark{
    border:1px solid rgb(105, 105, 105);
   margin-top:1.5rem ;
   margin-left:-0.5rem ;
   width: 35rem;
}
}