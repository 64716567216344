.manage-qr-section {
  padding: 1rem;
  box-sizing: border-box;

  .manage-qr-section-data {
    width: 100%;
    height: calc(100vh - 160px);
  }
}

.assign-batch-modal {
  .assign-batch-form-outerwrapper {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .assign-batch-form-wrapper {
      background: #ffffff;
      padding: 1rem;
      box-sizing: border-box;
      border-radius: 5px;
      width: 500px;
      height: 300px;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;

      .assign-batch-form-topsection {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }

      .assign-batch-form {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .form-control {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;


          .form-label {
            font-weight: 500;
            font-size: 1rem;
          }

          .form-input {
            font-size: 1rem;
            padding: 1rem;
            border: 1px solid #e4e3e3;
            border-radius: 5px;
          }

          .form-error {
            color: #ad3030;
            font-size: 0.87rem;
          }
        }

        .form-button-section {
          display: flex;
          justify-content: space-between;
          gap: 1rem;
          box-sizing: border-box;

          .form-button {
            width: 50%;
          }
        }


      }
    }
  }
}