.employers-section {
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .employers-data {
    width: 100%;
    height: calc(100vh - 160px);
    min-height: 400px;
  }

}

.create-employer-section {
  .create-employer {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    padding: 20px;

    .headingPlusBackBtn {
      display: flex;
      // border: 1px solid red;
      align-items: center;
      position: relative;
    }

    .formHeadings {
      font-size: 1.5rem;
    }

    .create-employer-form-wrapper {
      background: #ffffff;
      padding: 0px 10px;
      box-sizing: border-box;
      border-radius: 5px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;


      .create-employer-form {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .backgroundStyle {
          border: 1px solid #eeeeee;
          padding: 10px;
          border-radius: 7px;
          background-color: #fbfbfb;
        }

        .formSubSectionHeading {
          font-size: 1rem;
          margin-top: 0;
          font-weight: 600;
          color: #3d3d3d;
        }

        .cstmFormGridEmployer {
          margin-bottom: 2rem;
          padding: 0px 1rem;
        }

        .form-control {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;


          .form-label {
            font-weight: 500;
            font-size: 0.9rem;
          }

          .form-input {
            font-size: 0.9rem;
            padding: 10px;
            border: 1px solid #e4e3e3;
            border-radius: 5px;
          }

          .form-error {
            color: #ad3030;
            font-size: 0.87rem;
          }
        }

        .form-button-section {
          display: flex;
          justify-content: flex-end;
          gap: 1rem;
          box-sizing: border-box;

          .form-button {
            width: fit-content;

          }
        }


      }
    }
  }
}

.loaderClass {
  position: absolute;
  top: 0;
  height: 180px !important;
  border-radius: 5px;
  width: 100%;
  background-color: #00000054;
}

.semiBoldHeading {
  font-weight: 500;
}


.update-wallet-section {
  .update-wallet {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    padding: 30px;

    .headingPlusBackBtn {
      display: flex;
      // border: 1px solid red;
      align-items: center;
      position: relative;
    }

    .formHeadings {
      font-size: 1.5rem;
      margin: 10px 0px;
    }

    .update-wallet-form-wrapper {
      background: #ffffff;
      padding: 0px 10px;
      box-sizing: border-box;
      border-radius: 5px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;


      .update-wallet-form {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .backgroundStyle {
          border: 1px solid #eeeeee;
          padding: 100px;
          border-radius: 7px;
          background-color: #fbfbfb;


        }

        .formSubSectionHeading {
          font-size: 1rem;
          margin: 20px 0px;
          font-weight: 600;
          color: #3d3d3d;
        }

        .cstmFormGridWallet {
          // margin:8px 0px;
          // margin-bottom: 2rem;
          // padding: 0px 1rem;
        }

        .form-control {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;


          .form-label {
            font-weight: 500;
            font-size: 0.9rem;
          }

          .form-input {
            font-size: 0.9rem;
            padding: 10px;
            border: 1px solid #e4e3e3;
            border-radius: 5px;
          }

          .form-error {
            color: #ad3030;
            font-size: 0.87rem;
          }
        }

        .form-button-section {
          display: flex;
          justify-content: flex-end;
          gap: 1rem;
          box-sizing: border-box;

          .form-button {
            width: fit-content;

          }
        }


      }
    }
  }
}

.marginBottom10 {
  margin-bottom: 10px;
}