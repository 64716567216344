.sidebar {

  flex: 1;
  border-right: 0.05rem solid rgb(230, 227, 227);
  min-height: 100vh;
  background-color: white;

  .top {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1.4rem;
    // border-bottom: 1px solid #e7e4e4;
    position: sticky;
    top: 0;
    background-color: #fff;

    .logo {
      font-size: 1.2rem;
      font-weight: bold;
      color: #35035d;
    }
  }

  hr {
    height: 0;
    border: 0.05rem solid rgb(230, 227, 227);
  }

  .center {
    height: 86%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 10px 5px;
    margin-top: 4rem;

    .innerTreView {
      display: flex;
      align-items: start;
      overflow-x: hidden;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      .treeViewNewBlock {
        display: block !important;
        width: 100%;

        .marginLeft5 {
          margin-left: 5px;
          padding-left: 5px;
        }
      }

      li {
        display: flex;
        align-items: center;
        padding: 10px 2px;
        cursor: pointer;



        &:hover {
          // background-color: #a720d5;
          // color: #ff671f;

        }

        .icon {
          font-size: 1.8rem;
          color: #35035d;
        }

        .marginTop10 {
          margin-top: 10px !important;
        }

        span {
          font-size: 1rem;
          font-weight: 400;
          color: rgb(32, 30, 32);
          margin-left: 1.0rem;
        }
      }

      .nav-sub-sections {
        padding: 10px;



        .disabled {
          color: #9a81ae !important;
          cursor: default;
        }


        .unauthorized {
          display: none !important;
        }

        a {
          .unauthorized {
            display: none !important;
          }
        }

      }

      .title {
        font-size: 1.3rem;
        font-weight: bold;
        color: #ff671f;
        margin: 10px 0px 0px 0px;
      }


    }
  }

  .center::-webkit-scrollbar {
    width: 2px;
  }

  .center::-webkit-scrollbar-thumb {
    background: #ff671f;
  }

  .bottom {
    display: flex;
    align-items: center;
    margin: 1.0rem;

    .colorOption {
      width: 2.0rem;
      height: 2.0rem;
      border-radius: 0.5rem;
      border: 0.1rem solid #7451f8;
      cursor: pointer;
      margin: 0.5rem;

      &:nth-child(1) {
        background-color: whitesmoke;
      }

      &:nth-child(2) {
        background-color: #333;
      }

      &:nth-child(3) {
        background-color: darkblue;
      }
    }
  }
}

.MuiTreeItem-iconContainer {
  width: 0px !important;
  display: none !important;
}

.css-1bcfi89-MuiTreeItem-content.MuiTreeItem-content.Mui-selected {
  background-color: transparent !important;
}

.MuiTreeItem-label {
  font-size: 12px;
}

.css-1bcfi89-MuiTreeItem-content.MuiTreeItem-content.Mui-expanded.Mui-selected {
  padding: 0 !important;
}