 .partner-section {
   padding: 1rem;
   box-sizing: border-box;
   display: flex;
   flex-direction: column;
   gap: 1rem;

   .partner-data {
     width: 100%;
     height: calc(100vh - 200px);
   }

   .partner-candidate-data {
     width: 100%;
     height: 700px;
   }

 }



 .create-partner-section {
   .create-partner {
     display: flex;
     align-items: start;
     justify-content: center;
     flex-direction: column;
     padding: 20px;

     .headingPlusBackBtn {
       display: flex;
       align-items: center;
       position: relative;
     }


     .formHeadings {
       font-size: 1.5rem;
     }

     .create-partner-form-wrapper {
       background: #ffffff;
       padding: 0px 10px;
       box-sizing: border-box;
       border-radius: 5px;
       width: 100%;
       display: flex;
       flex-direction: column;
       gap: 0.8rem;


       .create-partner-form {
         display: flex;
         flex-direction: column;
         gap: 1rem;

         .backgroundStyle {
           border: 1px solid #eeeeee;
           padding: 10px;
           border-radius: 7px;
           background-color: #fbfbfb;


         }

         .formSubSectionHeading {
           font-size: 1rem;
           margin-top: 0;
           font-weight: 600;
           color: #3d3d3d;
         }

         .cstmFormGridEmployer {
           margin-bottom: 1rem;
           padding: 0px 1rem;
         }

         .form-control {
           display: flex;
           flex-direction: column;
           gap: 0.5rem;

           .months_label {
             background-color: #fff;
             color: #757575;
             border: 1px solid #e4e3e3;
             padding: 8px;
             border-radius: 4px;
             display: inline-block;

           }

           .form-label {
             font-weight: 500;
             font-size: 0.9rem;
           }

           .form-input {
             font-size: 0.9rem;
             padding: 10px;
             border: 1px solid #e4e3e3;
             border-radius: 5px;
           }

           .form-error {
             color: #ad3030;
             font-size: 0.87rem;
           }
         }

         .form-button-section {
           display: flex;
           justify-content: flex-end;
           gap: 1rem;
           box-sizing: border-box;

           .form-button {
             width: fit-content;
           }
         }


       }
     }
   }
 }

 .marg15 {
   margin: 15px 0px;
 }

 .text-success {
   color: green;
 }