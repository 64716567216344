.viewClientBtn {
  background-color: #1976d2;
  border-radius: 6px;
  font-size: 12px !important;
  padding: 4px 6px;
  color: white;
  cursor: pointer;
}

.pincodeLoader {
  width: 20px !important;
  height: 20px !important;
}

.sameAsInput {
  background-color: #f7f7f7;
  color: black;

  font-size: 0.8rem !important;
  padding: 10px;
  border: 1px solid #e4e3e3;
  border-radius: 5px;
}