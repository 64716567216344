.generate-qr-section {
    .generate-qr {
        padding: 1rem;
        box-sizing: border-box;

        .generate-qr-form-wrapper {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .generate-qr-form-row {
                display: flex;
                flex-direction: row;
                gap: 20px;
                // justify-content: space-between;

                .form-control {
                    display: flex;
                    flex-direction: column;
                    // justify-content: ;
                    // align-items: center;
                    width: 500px;

                    gap: 0.4rem;

                    .form-input {
                        padding: 10px;
                        border: 1px solid #c3c3c3;
                        border-radius: 5px;
                        font-size: 16px;
                    }

                    .form-error {
                        color: #ad3030;
                        margin: 10px 0px;
                    }
                }


            }

            .form-button-section {
                display: flex;
                justify-content: center;
                gap: 1rem;
                box-sizing: border-box;

            }

        }

    }
}