// .allcomponent{
//   margin: 0px;
//   width: 80vw;
//   height: 100vh;
//   font-size: 62.52%;
// }

.navbar {
  width: 100%;
  // height: 4rem;
  // display: flex;
  // align-items: center;
  font-size: 1.4rem;
  color: #080808;
  // width: 100vw;

  .wrapper {
    width: 100%;
    padding: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .search {
      display: flex;
      align-items: center;
      border: 0.5px solid lightgray;
      padding: 5px 15px;
      border-radius: 4px;

      input {
        border: none;
        outline: none;
        background: transparent;

        &::placeholder {
          font-size: 0.9rem;
        }
      }

      .searchIcon {
        color: #919191;
      }
    }

    .items {
      display: flex;
      align-items: center;

      .item {
        display: flex;
        align-items: center;
        // margin-right: 2.0rem;
        position: relative;

        .icon {
          font-size: 2.0rem;
        }

        .avatar {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          color: #ff671f;
        }

        .counter {
          width: 1.5rem;
          height: 1.5rem;
          background-color: red;
          border-radius: 50%;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.0rem;
          font-weight: bold;
          position: absolute;
          top: -0.5rem, ;
          right: -0.5rem;
        }
      }
    }
  }
}

@media (max-width:768px) {
  .website-layout-outer .cstmNavbar {
    padding: 0px 5px;
  }

  .navbar .wrapper {
    width: 100%;
    padding: 5px 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .items {
      .item {
        .avatar {
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }
}