.urlShortenerSection{
    padding: 20px;
    .urlShortenerHeader{
        width:fit-content;
        display: flex;
        flex-direction: column;
        gap:10px;
        margin-bottom: 15px;
    }    
}

.create-url-link-form {
    .cstmFormGridUrlLink {
        margin-bottom: 2rem;
        padding: 0px 1rem;
      }

      .form-control {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;


        .form-label {
          font-weight: 500;
          font-size: 0.9rem;
        }

        .form-input {
          font-size: 0.9rem;
          padding: 10px;
          border: 1px solid #e4e3e3;
          border-radius: 5px;
        }

        .form-error {
          color: #ad3030;
          font-size: 0.87rem;
        }
      }

      .form-button-section {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
        box-sizing: border-box;

        .form-button {
          width: fit-content;

        }
      }
}