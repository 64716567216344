.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;

  h1 {
    margin-bottom: 0;
  }

  img {
    width: 300px;
  }
}