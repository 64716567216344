.jobs-section {
  padding: 1rem;
  box-sizing: border-box;

  .jobs-section-data {
    width: 100%;
    height: calc(100vh - 180px);
  }
}

.job-rejection-modal {
  .job-rejection-form-outerwrapper {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .job-rejection-form-wrapper {
      background: #ffffff;
      padding: 1rem;
      box-sizing: border-box;
      border-radius: 5px;
      width: 500px;
      height: 300px;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;

      .job-rejection-form-topsection {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }

      .job-rejection-form {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .form-control {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;


          .form-label {
            font-weight: 500;
            font-size: 1rem;
          }

          .form-input {
            font-size: 1rem;
            padding: 1rem;
            border: 1px solid #e4e3e3;
            border-radius: 5px;
          }

          .form-error {
            color: #ad3030;
            font-size: 0.87rem;
          }
        }

        .form-button-section {
          display: flex;
          justify-content: space-between;
          gap: 1rem;
          box-sizing: border-box;

          .form-button {
            width: 50%;
          }
        }


      }
    }
  }
}

.approved-jobs-section {
  padding: 1rem;
  box-sizing: border-box;

  .approved-jobs-data {
    width: 100%;
    height: 500px;
  }
}

.MuiMenu-list {
  display: flex;
  flex-direction: column;
}

.rejected-jobs-section {
  padding: 1rem;
  box-sizing: border-box;

  .rejected-jobs-data {
    width: 100%;
    height: calc(100vh - 180px);
  }

  .section-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .chat-box {
    width: 400px;
    height: 100vh;
    position: fixed;
    top: 100px;
    right: 0;
    bottom: 0;
    background: white;
    border: 1px solid rgb(224 224 224);
    padding: 15px;
    z-index: 99;

    .inner-wrapper {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      height: 100%;
      position: relative;

      .chat-title {
        text-align: center;
      }

      .close-icon {
        position: absolute;
        right: 0;
        cursor: pointer;
      }
    }

    .admin-message {
      display: flex;
      flex-direction: column;
      align-items: start;

      .time {
        font-size: 0.8rem;
      }

      .message {
        text-align: justify;
        width: 300px;

      }

    }

    .appeal-message {
      display: flex;
      flex-direction: column;
      align-items: end;
      margin-top: 20px;

      .time {
        font-size: 0.8rem;
      }

      .message {
        display: flex;
        justify-content: flex-end;
        text-align: justify;
        width: 300px;
      }
    }
  }


}

.actionBtn {
  font-size: 13px !important;
  width: 100%;
  // padding: 4px 8px !important;
  // height: 100% !important;
}

.jobsahayaks-data {
  width: 100%;
  height: calc(90vh - 160px);
  min-height: 400px;
}

.newBtns {
  padding: 3px 4px;
  height: 30px;
}

.borderBx {
  background-color: #f2f2f2;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  padding: 6px;
}

.no-data-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 16px;
  font-weight: 500;
}