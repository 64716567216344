.create-new-users-section {
    padding: 0.8rem;
    box-sizing: border-box;

    .create-new-users-form-section {
        padding: 0.8rem;

        h1 {
            text-align: center;
        }

        .create-user-form {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .form-control {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;


                .form-label {
                    font-weight: 500;
                    font-size: 1rem;
                }

                .form-input {
                    font-size: 1rem;
                    padding: 1rem;
                    border: 1px solid #e4e3e3;
                    border-radius: 5px;
                    outline: none;
                }

                .form-error {
                    color: #ad3030;
                    font-size: 0.87rem;
                }
            }

            .form-button-section {
                display: flex;
                justify-content: space-between;
                gap: 1rem;
                box-sizing: border-box;

                .form-button {
                    width: 50%;
                }
            }


        }


    }
}