.userMessageSection {
  overflow: hidden;

  .userMessageSectionWrapper {
    padding: 20px;
    height: 100dvh;
    box-sizing: border-box;

    .backSection {
      display: flex;
      gap: 10px;
      align-items: center;
      cursor: pointer;
    }

    .userMessageContainer {
      width: 100%;
      margin: 0px;

      .userSection {
        height: 90vh;
        overflow-y: auto;
        overflow-x: hidden;
        border: 1px solid #d5d5d5;
        border-radius: 8px;
        background-color: #f3f2f2;

        @media screen and (max-width: 900px) {
          display: none;
        }

        .userInnerSection {

          .userListLoader {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 80vh;
          }

          .noMessages {
            padding: 10px;
          }

          // padding: 10px;
          .userSearchSection {
            position: sticky;
            top: 0px;
            background: #5b5b5b;
            padding: 10px 8px;
          }

          .userSearchSection1 {

            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            border: 1px solid #d5d5d5;
            padding: 5px;
            border-radius: 8px;
            background: #d5d5d5;
            position: relative;

            .searchBtn {
              background-color: #f4f4f4;
              box-shadow: 2px 2px 10px #5a5a5a;
              width: 100px;
              width: 52px !important;
              min-width: 20px !important;
            }

            .searchBtn:hover {
              background-color: #efefef;
              box-shadow: 3px 3px 15px #5a5a5a;
            }

            .numberLoaderClass {
              position: absolute;
              right: 5px;
              width: 20px !important;
              height: 20px !important;
              color: #ff6e00;
            }

            .userSearchInput {
              border: none;
              background: none;
              font-size: 16px;
              outline: none;
              margin-left: 10px;
              width: 100%;
            }
          }

          .userInfo {
            padding: 10px 20px;
            border-bottom: 1px solid #d5d5d5;
            cursor: pointer;

          }

          .userInfo2 {
            box-shadow: 0px 0px 4px #bfbfbf inset;
            border-radius: 7px;
            background-color: #e4e4e4;
          }
        }
      }

      .chatSection {
        // padding: 0px;
        // flex-grow: 9;
        border: 1px solid #d5d5d5;
        border-radius: 8px;
        height: 90vh;
        background-color: #f3f2f2;

        .chatSectionHeader {
          background: #5b5b5b;
          color: white;
          font-size: 18px;
          padding: 12px;
          position: sticky;
          top: 0;
          height: 30px;
          border-top-left-radius: 8px;
        }

        .chatSectionWrapper {
          padding: 10px;
          height: calc(90vh - 80px);
          overflow-y: auto;

          .forMainLoader {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 80vh;
            width: 100%;
          }

          .noMessages {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 10px;
            align-items: center;
            height: 80dvh;
            font-size: 20px;
            color: #5b5b5b;
          }

          .chatSectionInnerWrapper {
            .userMessageWrapper {
              width: 100%;
              display: flex;
              justify-content: flex-end;

              .userMessage {
                width: 40%;
                background: #6593e2;
                border-radius: 8px;
                padding: 10px;
                margin-bottom: 10px;
                color: white;

                @media screen and (max-width: 900px) {
                  width: 70%;
                }

                .message {
                  line-height: 1.5;
                }

                .messageTime {
                  display: flex;
                  justify-content: flex-end;
                  font-weight: 600;
                  font-size: 14px;
                  margin-top: 15px;
                  color: #ccdbf4;
                }
              }
            }

            .sendMessageWrapper {
              width: 100%;
              display: flex;
              justify-content: flex-start;

              .sendMessage {
                width: 40%;
                background: #afa8a8;
                border-radius: 8px;
                padding: 10px;
                margin-bottom: 10px;
                color: white;

                @media screen and (max-width: 900px) {
                  width: 70%;
                }

                .message {
                  line-height: 1.5;
                }

                .messageTime {
                  display: flex;
                  justify-content: flex-end;
                  font-weight: 600;
                  font-size: 14px;
                  margin-top: 15px;
                  color: #ddd8d8;
                }
              }
            }
          }
        }
      }
    }
  }
}