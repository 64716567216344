.home_section {

  .widgets,
  .charts {

    display: flex;
    padding: 2.0rem;
    gap: 2.0rem;
    padding-bottom: 0px;
  }


  .charts {
    // width: 50%;
    padding: 0.5rem 2.0rem;
  }

  @media (max-width:768px) {
    .charts {
      flex-direction: column;
    }
  }

  .listContainer {
    // width: 90%;
    -webkit-box-shadow: 0.2rem 0.4rem 1.0rem 0.1rem rgba(0, 0, 0, 0.47);
    box-shadow: 0.2rem 0.4px 10rem 0.1rem rgba(201, 201, 201, 0.47);
    padding: 1.0rem;
    margin-right: 1.0rem;
    margin-right: 0.5rem;


    .listTitle {
      // width: 90%;
      font-weight: bold;
      color: rgb(119, 10, 228);
      margin-bottom: 1.5rem;
      margin-right: 0.5rem;

    }
  }
}