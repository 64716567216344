.announcements-section {
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .announcements-data {
    width: 100%;
    height: calc(100vh - 160px);
  }

}

.create-announcement-section {
  .create-announcement {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    padding: 20px;

    .headingPlusBackBtn {
      display: flex;
      align-items: center;
      position: relative;
    }

    .formHeadings {
      font-size: 1.5rem;
    }

    .create-announcement-form-wrapper {
      background: #ffffff;
      padding: 0px 10px;
      box-sizing: border-box;
      border-radius: 5px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;


      .create-announcement-form {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .backgroundStyle {
          border: 1px solid #eeeeee;
          padding: 10px;
          border-radius: 7px;
          background-color: #fbfbfb;
        }

        .formSubSectionHeading {
          font-size: 1rem;
          margin-top: 0;
          font-weight: 600;
          color: #3d3d3d;
        }

        .cstmFormGridAnnouncement {
          margin-bottom: 1rem;
          padding: 0px 1rem;
        }

        .form-control {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;


          .form-label {
            font-weight: 500;
            font-size: 0.9rem;
          }

          .form-input {
            font-size: 0.9rem;
            padding: 10px;
            border: 1px solid #e4e3e3;
            border-radius: 5px;
          }

          .form-error {
            color: #ad3030;
            font-size: 0.87rem;
          }
        }

        .form-button-section {
          display: flex;
          justify-content: flex-end;
          gap: 1rem;
          box-sizing: border-box;

          .form-button {
            width: fit-content;

          }
        }


      }
    }
  }
}

.candidate-pool-section {

  .candidate-pool-top-section {
    padding: 1rem;
    box-sizing: border-box;

    .job-info-section {
      display: flex;
      gap: 1rem;

      .form-input {
        display: flex;
        gap: 1rem;
        align-items: center;
      }
    }
  }

  .job-tab-section {
    padding: 1rem;
    border: 1px solid #e7e4e4;
    display: flex;
    gap: 1rem;
  }

  .candidates-section-data {
    padding: 1rem;
    width: 100%;
    height: calc(100vh - 350px);
  }
}

.jobseva-jobs-section {
  padding: 1rem;
  box-sizing: border-box;

  .jobseva-job-section {
    display: flex;
    justify-content: space-between;

    .jobseva-job-button-sections {
      display: flex;
      flex-direction: row;
    }
  }

  .jobs-section-data {
    width: 100%;
    height: calc(100vh - 100px);
  }
}

.job-seva-task-form {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .job-seva-task-form-control {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;

    .form-input {
      font-size: 1rem;
      padding: 0.5rem;
      border: 1px solid #c4b9b9;
      border-radius: 5px;
    }


  }

  .form-button-section {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
  }
}

.job-seva-assigntask-form {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .job-seva-assigntask-form-control {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;

    .form-input {
      font-size: 1rem;
      padding: 0.5rem;
      border: 1px solid #c4b9b9;
      border-radius: 5px;
    }


  }

  .form-button-section {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
  }
}

.formHeadings {
  font-size: 1.5rem;
  margin: 0;
}

.pac-container {
  z-index: 1400 !important;
}

.JobShayakName {
  padding: 0px !important;
}

.headingPlusBackBtn {
  display: flex;
  align-items: center;
  position: relative;
}

.create-jsk-section {
  .create-jsk {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    padding: 20px;

    .headingPlusBackBtn {
      display: flex;
      // border: 1px solid red;
      align-items: center;
      position: relative;
    }

    .formHeadings {
      font-size: 1.5rem;
    }

    .create-jsk-form-wrapper {
      background: #ffffff;
      padding: 0px 10px;
      box-sizing: border-box;
      border-radius: 5px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;


      .create-jsk-form {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .backgroundStyle {
          border: 1px solid #eeeeee;
          padding: 10px;
          border-radius: 7px;
          background-color: #fbfbfb;
        }

        .formSubSectionHeading {
          font-size: 1rem;
          margin-top: 0;
          font-weight: 600;
          color: #3d3d3d;
        }

        .cstmFormGridEmployer {
          margin-bottom: 1rem;
          padding: 0px 1rem;
        }

        .form-control {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          .datePickerGetInput>div>input {
            padding: 8.5px 14px;
            font-size: 0.9rem;
          }

          .radioBtnDiv {
            flex-direction: row;
          }

          .radioBtnDiv>label span:nth-child(even) {
            font-size: 0.9rem;
          }



          .form-label {
            font-weight: 500;
            font-size: 0.9rem;
          }

          .form-input {
            font-size: 0.9rem;
            padding: 10px;
            border: 1px solid #e4e3e3;
            border-radius: 5px;
          }

          .form-error {
            color: #ad3030;
            font-size: 0.87rem;
          }
        }

        .form-button-section {
          display: flex;
          justify-content: flex-end;
          gap: 1rem;
          box-sizing: border-box;
          // padding: 0px 1rem;

          .form-button {
            width: fit-content;
          }
        }


      }
    }
  }
}

.create-jwp-section {
  .create-jwp {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    padding: 20px;

    .headingPlusBackBtn {
      display: flex;
      // border: 1px solid red;
      align-items: center;
      position: relative;
    }

    .formHeadings {
      font-size: 1.5rem;
    }

    .create-jwp-form-wrapper {
      background: #ffffff;
      padding: 0px 10px;
      box-sizing: border-box;
      border-radius: 5px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;


      .create-jwp-form {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .backgroundStyle {
          border: 1px solid #eeeeee;
          padding: 10px;
          border-radius: 7px;
          background-color: #fbfbfb;
        }

        .formSubSectionHeading {
          font-size: 1rem;
          margin-top: 0;
          font-weight: 600;
          color: #3d3d3d;
        }

        .cstmFormGridEmployer {
          margin-bottom: 1rem;
          padding: 0px 1rem;
        }

        .form-control {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          .datePickerGetInput>div>input {
            padding: 8.5px 14px;
            font-size: 0.9rem;
          }

          .radioBtnDiv {
            flex-direction: row;
          }

          .radioBtnDiv>label span:nth-child(even) {
            font-size: 0.9rem;
          }



          .form-label {
            font-weight: 500;
            font-size: 0.9rem;
          }

          .form-input {
            font-size: 0.9rem;
            padding: 10px;
            border: 1px solid #e4e3e3;
            border-radius: 5px;
          }

          .form-error {
            color: #ad3030;
            font-size: 0.87rem;
          }
        }

        .form-button-section {
          display: flex;
          justify-content: flex-end;
          gap: 1rem;
          box-sizing: border-box;
          // padding: 0px 1rem;

          .form-button {
            width: fit-content;
          }
        }


      }
    }
  }
}

.create-candidates-section {
  .create-candidates {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    padding: 20px;

    .headingPlusBackBtn {
      display: flex;
      // border: 1px solid red;
      align-items: center;
      position: relative;
    }

    .formHeadings {
      font-size: 1.5rem;
    }

    .create-candidates-form-wrapper {
      background: #ffffff;
      padding: 0px 10px;
      box-sizing: border-box;
      border-radius: 5px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;


      .create-candidates-form {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .backgroundStyle {
          border: 1px solid #eeeeee;
          padding: 10px;
          border-radius: 7px;
          background-color: #fbfbfb;
        }

        .formSubSectionHeading {
          font-size: 1rem;
          margin-top: 0;
          font-weight: 600;
          color: #3d3d3d;
        }

        .cstmFormGridEmployer {
          margin-bottom: 1rem;
          padding: 0px 1rem;
        }

        .form-control {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          .datePickerGetInput>div>input {
            padding: 8.5px 14px;
            font-size: 0.9rem;
          }

          .radioBtnDiv {
            flex-direction: row;
          }

          .radioBtnDiv>label span:nth-child(even) {
            font-size: 0.9rem;
          }



          .form-label {
            font-weight: 500;
            font-size: 0.9rem;
          }

          .form-input {
            font-size: 0.9rem;
            padding: 10px;
            border: 1px solid #e4e3e3;
            border-radius: 5px;
          }

          .form-error {
            color: #ad3030;
            font-size: 0.87rem;
          }
        }

        .form-button-section {
          display: flex;
          justify-content: flex-end;
          gap: 1rem;
          box-sizing: border-box;
          // padding: 0px 1rem;

          .form-button {
            width: fit-content;
          }
        }


      }
    }
  }
}