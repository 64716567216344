.searchbar-section {
  .searchbar-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid black;
    width: 100%;
    max-width: 600px;
    border: 1px solid #d9d9d9;
    padding: 5px;
    border-radius: 3px;

    input {
      border: none;
      outline: none;
      width: 100%;
      font-size: 14px;
    }
  }

}