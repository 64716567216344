.cstmDateTimePicker {
  div {
    input {
      padding: 7px !important;
      background-color: #fff;
    }
  }
}

.cstmSelectBox {
  div {
    padding: 7px;
    background-color: #fff;
  }
}

.fileUpload {
  display: flex;
  flex-direction: column;
}

.borderClass {
  border: 1px solid rgb(222, 222, 222);
  padding: 10px;
  border-radius: 7px;
}

.imgVidBorder {
  display: flex;
  min-width: 200px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.gapClass {
  gap: 10px;
  justify-content: space-evenly;
}

.imgColor {
  width: 80px !important;
  height: 80px !important;
  color: #ff671fdb;
}

.sizeNote {
  font-size: 12px !important;
  color: grey;
  margin-bottom: 10px;
}

.badgeCstm {
  border-radius: 5px;
  background-color: #e6e6e6;
  padding: 20px;
  font-size: 1rem;
  text-align: center;
}

.badgeCstmSpan {
  font-size: 1.1rem;
  font-weight: 700;
}

.loaderOne {
  z-index: 10;
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.111);
  height: 110px;
  width: 100%;
  margin-left: -9px;

}

.loaderTwo {
  z-index: 10;
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.111);
  height: 348px;
  width: 100%;
  margin-left: -9px;

}

.datePickerGetInput>div>input {
  padding: 8.5px 14px;
  font-size: 0.9rem;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input {
  width: 98%;
  border: none;
}

.create-whatsapp-campaign-section {
  .create-whatsapp-campaign {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    padding: 20px;

    .headingPlusBackBtn {
      display: flex;
      // border: 1px solid red;
      align-items: center;
      position: relative;
    }

    .formHeadings {
      font-size: 1.5rem;
    }

    .create-whatsapp-campaign-form-wrapper {
      background: #ffffff;
      // padding: 0px 10px;
      box-sizing: border-box;
      border-radius: 5px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;


      .create-whatsapp-campaign-form {
        display: flex;
        flex-direction: column;
        // gap: 1rem;

        .backgroundStyle {
          border: 1px solid #eeeeee;
          padding: 10px;
          border-radius: 7px;
          background-color: #fbfbfb;
        }

        .formSubSectionHeading {
          font-size: 1rem;
          margin-top: 0;
          font-weight: 600;
          color: #3d3d3d;
        }

        .cstmFormGridEmployer {
          margin-bottom: 1rem;
          padding: 0px 1rem;
        }

        .form-control {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          .datePickerGetInput>div>input {
            padding: 8.5px 14px;
            font-size: 0.9rem;
          }

          .radioBtnDiv {
            flex-direction: row;
          }

          .radioBtnDiv>label span:nth-child(even) {
            font-size: 0.9rem;
          }



          .form-label {
            font-weight: 500;
            font-size: 0.9rem;
          }

          .form-input {
            font-size: 0.9rem;
            padding: 10px;
            border: 1px solid #e4e3e3;
            border-radius: 5px;
          }

          .form-error {
            color: #ad3030;
            font-size: 0.87rem;
          }
        }

        .form-button-section {
          display: flex;
          justify-content: flex-end;
          gap: 1rem;
          box-sizing: border-box;
          margin-top: 10px;
          // padding: 0px 1rem;

          .form-button {
            width: fit-content;
          }
        }


      }
    }
  }
}

.create-notification-campaign-section {
  .create-notification-campaign {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    padding: 20px;

    .headingPlusBackBtn {
      display: flex;
      // border: 1px solid red;
      align-items: center;
      position: relative;
    }

    .formHeadings {
      font-size: 1.5rem;
    }

    .create-notification-campaign-form-wrapper {
      background: #ffffff;
      // padding: 0px 10px;
      box-sizing: border-box;
      border-radius: 5px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;


      .create-notification-campaign-form {
        display: flex;
        flex-direction: column;
        // gap: 1rem;

        .backgroundStyle {
          border: 1px solid #eeeeee;
          padding: 10px;
          border-radius: 7px;
          background-color: #fbfbfb;
        }

        .formSubSectionHeading {
          font-size: 1rem;
          margin-top: 0;
          font-weight: 600;
          color: #3d3d3d;
        }

        .cstmForGridNotification {
          margin-bottom: 1rem;
          padding: 0px 1rem;
        }

        .form-control {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          .datePickerGetInput>div>input {
            padding: 8.5px 14px;
            font-size: 0.9rem;
          }

          .radioBtnDiv {
            flex-direction: row;
          }

          .radioBtnDiv>label span:nth-child(even) {
            font-size: 0.9rem;
          }



          .form-label {
            font-weight: 500;
            font-size: 0.9rem;
          }

          .form-input {
            font-size: 0.9rem;
            padding: 10px;
            border: 1px solid #e4e3e3;
            border-radius: 5px;
          }

          .form-error {
            color: #ad3030;
            font-size: 0.87rem;
          }
        }

        .form-button-section {
          display: flex;
          justify-content: flex-end;
          gap: 1rem;
          box-sizing: border-box;
          margin-top: 10px;
          // padding: 0px 1rem;

          .form-button {
            width: fit-content;
          }
        }


      }
    }
  }
}

.exPlaceholder {
  font-size: 11px !important;
  color: #909090 !important;
  font-weight: 500 !important;
  padding-left: 10px !important;
  margin-top: -8px !important;
}