.user-queries-section {
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .section-title {
    font-size: 20px;
    font-weight: 600;
    // margin-bottom: 15px;
  }

  .user-queries-data {
    width: 100%;
    height: calc(100vh - 160px);
    min-height: 400px;
  }
}
