.login_outer_box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;

  .gradient_border_login {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}